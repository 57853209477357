@keyframes dotPulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1);
    }

    62.5% {
        transform: scale(1);
    }

    75% {
        transform: scale(2);
    }

    87.5% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.dots {
    @apply relative w-32 h-14 flex flex-col justify-between items-center -left-16;

    .underlay {
        @apply absolute w-[18rem] h-14 top-7 -left-20 bg-stone-100 z-0;
    }

    $n: 4;

    .row {
        @apply w-full flex flex-row justify-between items-center;

        .col {
            @apply flex justify-center items-center;

            .dot {
                @apply inline-block bg-green-500 w-0.5 h-0.5 rounded-full scale-100;

                animation: dotPulse 2.7s infinite;
                animation-delay: attr(data-row s);
            }
        }
    }

    @for $i from 1 through $n {
        .row:nth-child(#{$i}) {
            .col {
                .dot {
                    animation-delay: (0.1s * $i);
                }
            }
        }
    }
}
