div.slider {
    @apply h-[33vh] md:h-[66vh] relative;

    section.slide {
        @apply transition-opacity ease-in-out duration-1000 opacity-0 absolute bg-center bg-cover h-full w-full pointer-events-none;

        font-size: 0.8rem;
        p {
            font-size: 0.8rem;
        }

        @media (min-width: 768px) {
            font-size: 1.1rem;
            p {
                font-size: 1.1rem;
            }
        }

        h1,
        h2 {
            text-decoration: none !important;
            border: none;
            padding: 0;
        }

        &.active {
            @apply opacity-100 pointer-events-auto;
        }
    }
}

.hero {
    @apply h-[33vh] md:h-[66vh] bg-center bg-cover bg-no-repeat;

    font-size: 0.8rem;
    p {
        font-size: 0.8rem;
    }

    @media (min-width: 768px) {
        font-size: 1.1rem;
        p {
            font-size: 1.1rem;
        }
    }

    h1,
    h2 {
        text-decoration: none !important;
        border: none;
        padding: 0;
    }
}
