@tailwind base;
@tailwind components;
@tailwind utilities;

.wmde-markdown {
  background-color: transparent !important;
  color: inherit !important;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: inherit !important;
    border-bottom: none;
  }
}

body {
  font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-stone-100 m-0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  font-size: 1.1rem;
  a {
    color: rgb(49, 186, 96 / 1);
  }
}

.wmde-markdown {
  a {
    color: rgb(49, 186, 96 / 1) !important;
  }
}

button.button,
a.button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff !important;
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: #34ba5e !important;

  &:hover {
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    box-shadow:
      0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
}

header {
  @apply py-4 px-8;

  .menu {
    & > li {
      @apply relative py-4 px-2;

      & > a {
        @apply uppercase whitespace-nowrap;
        letter-spacing: 5.5px;
        font-size: 0.675em;
        font-weight: 700;

        svg {
          @apply rotate-0 transition-transform ease-in-out duration-75;
        }
      }

      & > ul {
        @apply bg-white p-8 -mx-8 hidden min-w-full;
      }
    }
  }

  .menu:not(.mobile) {
    @apply hidden md:flex flex-row justify-around flex-wrap;

    & > li {
      & > ul {
        @apply absolute;

        li {
          @apply mt-4 first-of-type:mt-0 whitespace-nowrap;
        }
      }

      &:hover {
        & > a {
          @apply text-green-500;
          svg {
            @apply rotate-180;
          }
        }

        & > ul {
          @apply absolute bg-white p-8 -mx-8 block z-20;
        }
      }
    }
  }

  .menu.mobile {
    @apply hidden;

    &.active {
      @apply flex flex-col;
    }

    & > li {
      &:hover {
        & > a {
          @apply text-green-500;
        }
      }

      &.active {
        & > a {
          @apply text-green-500;
          svg {
            @apply rotate-180;
          }
        }

        & > ul {
          @apply bg-white p-8 -mx-8 block z-20;

          li {
            @apply mt-4 first-of-type:mt-0 whitespace-nowrap;
          }
        }
      }
    }
  }

  .menu-wrapper.mobile {
    @apply block md:hidden;
  }

  .menu-wrapper.mobile.active {
    @apply bg-white shadow fixed z-20;
    padding: 0 1rem 0 1rem;
    margin: 0 -1rem 0 -1rem;
    width: calc(100vw - 3rem);
  }
}

.container {
  @apply shadow-md bg-white;
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

footer {
  @apply py-4 px-6 md:px-36;

  .links {
    @apply md:text-right;

    a {
      @apply block;
    }
  }

  hr {
    @apply my-8;
  }

  p {
    @apply mb-8;
  }
}

blockquote::before {
  content: '"';
}
blockquote::after {
  content: '"';
}

article,
section {
  h1 {
    @apply font-bold text-3xl md:text-4xl mb-4;
  }
  h2 {
    @apply font-bold text-2xl md:text-3xl mb-4;
  }
  h3 {
    @apply font-bold text-xl md:text-2xl mb-4;
  }
  h4 {
    @apply font-bold text-lg md:text-xl mb-4;
  }
  h5 {
    @apply font-normal text-base md:text-lg mb-4;
  }
  p {
    @apply mb-4;
    font-size: 1.1rem;
  }
}

article {
  iframe {
    @apply mx-auto;
  }

  section {
    @apply mb-4;
  }
}

.wmde-markdown {
  ul {
    list-style: "-";
    font-size: 1.1rem;
  }
}

ul.ticks {
  list-style: none !important;

  li {
    @apply relative;
  }

  li:before {
    content: "✓";
    @apply text-green-500 -left-8 absolute;
  }
}

ol {
  list-style: decimal;
}

.images {
  &.vertical {
    img {
      @apply mx-auto;
    }
  }

  &.horizontal {
    @apply grid-flow-col justify-center items-center w-full gap-4;
    display: grid;

    img {
      @apply w-full flex-shrink;
    }
  }

  &.vertical-smaller {
    img {
      @apply mx-auto;
      @media (min-width: 768px) {
        max-width: 80%;
      }
    }
  }

  &.stacked {
    @apply flex flex-col relative;

    img {
      @apply w-4/5;

      &:first-of-type {
        @apply self-start z-10;
      }

      &:last-of-type {
        @apply self-end relative -mt-[20vw] z-0;
      }
    }
  }

  &.stacked {
    @apply flex flex-col relative;

    img {
      @apply w-4/5;

      &:first-of-type {
        @apply self-start z-10;
      }

      &:last-of-type {
        @apply self-end relative -mt-[20vw] z-0;
      }
    }
  }

  &.grid {
    @apply grid-cols-3 gap-4;

    & > div {
      aspect-ratio: 4 / 3;
      background-size: cover;
      background-position: center;
    }
  }
}

.MuiButton-containedPrimary {
  background-color: rgb(52 186 94) !important;
}

.strike {
  text-decoration: line-through;
}
.pending {
  color: darkorange;
}

div.logo {
  width: 315px;
}

table.shipping {
  border: none;

  thead {
    tr {
      border-bottom: solid 1px black;
    }
  }
  tr {
    border: none;
    th {
      border: none;
    }
    td {
      border: none;
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  @apply bg-green-500;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

.instagram-gallery {
  .instagram-items {
    display: grid;
    // 3 columns
    grid-template-columns: repeat(3, 1fr);

    .instagram-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      position: relative;
      cursor: pointer;

      &::after {
        pointer-events: none;
        @media (min-width: 1024px) {
          content: attr(data-captionmob);
          @media (min-width: 1280px) {
            content: attr(data-caption);
          }
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: black;
          color: white;
          font-size: 0.8rem;
          opacity: 0;
          transition: 0.3s;
        }
      }
      &:hover {
        &::after {
          opacity: 0.8;
        }
      }
    }
  }
}

.how-it-works-row {
  min-height: 10.5rem;
  @media (min-width: 1280px) {
    min-height: 8.5rem;
  }
}
